import React from "react";
import "./Footer.scss";
import {FaLocationArrow, FaMobileAlt, FaEnvelope } from "react-icons/fa"    
import { useNavigate } from "react-router-dom";

const Footer = () => {

    const navigate =useNavigate()

    return <footer className="footer">
        <div className="footer-content">
            <div className="col">
                <div className="title">About</div>
                <div className="text">
                As it has been in our history; Today Junaid Jewellers is serving you different designs with competitive prices, high quality, fast service and guarantee for generations to come.
                </div>
            </div>
            <div className="col">
            <div className="title">Contact Us</div>
            <div className="c-item">
                <FaLocationArrow />
                <div className="text">
                304 Farnham Road Slough- Berks- SL1 4XL, United Kingdom
                </div>
            </div>
            <div className="c-item">
                <FaMobileAlt />
                <div className="text">
                  Phone:+44 1753554480
                </div>
            </div>
            <div className="c-item">
                <FaEnvelope/>
                <div className="text">
                 Email: iqbaljewellers@hotmail.com
                </div>
            </div>
            </div>
            <div className="col">
            <div className="title">Categories</div>
            <span className="text">Rings</span>
            <span className="text">Bangles</span>
            <span className="text">Locket</span>
            <span className="text">Gold sets</span>
            <span className="text">Bracelet</span>
            </div>
            <div className="col">
            <div className="title">Pages</div>
            <span className="text" onClick={()=> navigate("/")}>Home</span>
            <span className="text">About</span>
            <span className="text">Contact us</span>
            <span className="text">Privacy Policy</span>
            <span className="text">Terms And conditions</span>
            </div> 
        </div>
        <div className="bottom-bar">
            <div className="bottom-bar-content">
                <div className="text">
                    Junaid Jewellers 2024 Created By Shahrukh Dev. Preimium E-Commerce Solutions
                </div>
                <img src="/assets/payments.png" alt="Payments" />

            </div>
        </div>
    </footer>;
};

export default Footer;
